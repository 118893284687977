import gql from 'graphql-tag';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import { TranslatableText as TranslatableTextType } from '@/types/text';
import { Author as AuthorType } from '@/types/author';
import { MissionCategory } from '@/types/missionCategory';
import ClientPermissions, {
  IClientPermissions,
} from './fragments/ClientPermissions';
import ClientMarketplaceDescriptor from './fragments/ClientMarketplaceDescriptor';
import ClientCrmDescriptor from './fragments/ClientCrmDescriptor';
import Author from './fragments/Author';

export interface GetClientPermissionsVariables {
  clientId: string;
}
export interface GetClientPermissionsResult {
  client: {
    id: string;
    creationDate: string;
    permissions: IClientPermissions;
  };
}
export const GET_CLIENT_PERMISSIONS = gql`
  query getClientPermissions($clientId: ID!) {
    client(id: $clientId) {
      id
      creationDate
      permissions {
        ...ClientPermissions
      }
    }
  }
  ${ClientPermissions}
`;

export interface GetRecommendedProfilesCountVariables {
  clientId: string;
}
export interface GetRecommendedProfilesCountResult {
  client: {
    id: string;
    offers: Array<{
      id: string;
      title: string;
      recommendedProfilesFeed: {
        total: number;
      };
    }>;
  };
}
export const GET_RECOMMENDED_PROFILES_COUNT = gql`
  query getRecommendedProfilesCount($clientId: ID!) {
    client(id: $clientId) {
      id
      offers(activeOnly: true) {
        id
        title
        recommendedProfilesFeed {
          total
        }
      }
    }
  }
`;

export const GET_CLIENT_PROFILE_CUSTOM_FIELDS = gql`
  query getClientProfileCustomFields($clientId: ID!) {
    client(id: $clientId) {
      id
      profileCustomFields {
        type
        id
        display
        title {
          ...TranslatableText
        }
        contactCategory {
          type
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
          isMultiselect
        }
        shouldDisplayInCard
        shouldBeHidden
        shouldDisplayBelowFullname
        isDisplayedAtCreation
        isMandatoryAtCreation
      }
    }
  }
  ${TranslatableText}
`;

export const GET_CLIENT_MISSION_CUSTOM_FIELDS = gql`
  query getClientMissionCustomFields($clientId: ID!) {
    client(id: $clientId) {
      id
      missionCustomFields {
        type
        id
        isMandatoryAtCreation
        isDisplayedAtCreation
        title {
          ...TranslatableText
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
          isMultiselect
          isFilteringOption
        }
        shouldDisplayInCard
        shouldBeHidden
      }
    }
  }
  ${TranslatableText}
`;

export const GET_CLIENT_PRIVACY_SETTINGS = gql`
  query getClientPrivacySettings($clientId: ID!) {
    client(id: $clientId) {
      id
      privacySettings {
        dpo {
          email
          fullname
          address
          phoneNumber
        }
        euRepresentative {
          email
          fullname
          address
          phoneNumber
        }
      }
    }
  }
`;

export const GET_CLIENT_MARKETPLACE_DESCRIPTOR = gql`
  query getClientMarketplaceDescriptor($clientId: ID!) {
    client(id: $clientId) {
      id
      marketplaceDescriptor {
        ...ClientMarketplaceDescriptor
      }
    }
  }
  ${TranslatableText}
  ${ClientMarketplaceDescriptor}
`;

export const GET_CLIENT_CRM_DESCRIPTOR = gql`
  query getClientCrmDescriptor($clientId: ID!) {
    client(id: $clientId) {
      id
      crmDescriptor {
        ...ClientCrmDescriptor
      }
    }
  }
  ${TranslatableText}
  ${ClientCrmDescriptor}
`;

export interface ClientPipeline {
  id: string;
  scopeDescriptor: {
    type: string;
    departmentId?: string;
    sectionId?: string;
    subsectionId?: string;
  };
  missionCategory: MissionCategory;
  steps: {
    id: string;
    title: TranslatableTextType;
  }[];
  title: TranslatableTextType;
  author: AuthorType;
}

export const GET_CLIENT_MISSION_INTERESTED_SUB_PIPELINE = gql`
  query getMissionInterestedSubPipelines($clientId: ID!) {
    client(id: $clientId) {
      missionInterestedSubPipelines {
        id
        scopeDescriptor {
          type
          departmentId
          sectionId
          subsectionId
        }
        steps {
          id
          title {
            ...TranslatableText
          }
        }
        title {
          ...TranslatableText
        }
        author {
          ...Author
        }
      }
    }
  }
  ${TranslatableText}
  ${Author}
`;

export const DELETE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE = gql`
  mutation DeleteClientMissionPipelines(
    $input: DeleteClientMissionPipelineInput
  ) {
    deleteClientMissionPipelines(input: $input) {
      id
      missionInterestedSubPipelines {
        id
        scopeDescriptor {
          type
          departmentId
          sectionId
          subsectionId
        }
        steps {
          id
          title {
            default
          }
        }
        title {
          default
        }
        author {
          ...Author
        }
      }
    }
  }
  ${Author}
`;

export const CREATE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE = gql`
  mutation CreateClientMissionPipelines(
    $input: CreateClientMissionPipelineInput
  ) {
    createClientMissionPipelines(input: $input) {
      id
      missionInterestedSubPipelines {
        id
        scopeDescriptor {
          type
          departmentId
          sectionId
          subsectionId
        }
        steps {
          id
          title {
            default
          }
        }
        title {
          default
        }
        author {
          ...Author
        }
      }
    }
  }
  ${Author}
`;

export const UPDATE_CLIENT_MISSION_INTERESTED_SUB_PIPELINE = gql`
  mutation UpdateClientMissionPipelines(
    $input: UpdateClientMissionPipelineInput
  ) {
    updateClientMissionPipelines(input: $input) {
      id
      missionInterestedSubPipelines {
        id
        scopeDescriptor {
          type
          departmentId
          sectionId
          subsectionId
        }
        title {
          default
        }
        steps {
          id
          title {
            default
          }
        }
        author {
          ...Author
        }
      }
    }
  }
  ${Author}
`;

export const UPDATE_CLIENT_PERMISSIONS = gql`
  mutation updateClientPermissions($input: UpdateClientPermeissionsInput) {
    updateClientPermissions(input: $input) {
      permissions {
        ...ClientPermissions
      }
      id
    }
  }
  ${ClientPermissions}
`;
